* {
    box-sizing: border-box;
}

body {
    height: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

:root {
    --sidebar-width: 80px;
}
